<template>
    <div v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.5)">
        <el-input v-model="pageInfo.sku" placeholder="请输入SKU" style="width:200px;margin-right:10px" clearable @keyup.enter.native = "find"></el-input>
        <el-select v-model="pageInfo.status" clearable style="width:200px;margin-right:10px" placeholder="请选择商品状态" @change="find">
            <el-option
            v-for="item in productStatusList"
            :key="item.id"
            :label="item.value" 
            :value="item.id">
            </el-option>
        </el-select>
        <el-button type='primary' @click="find">查询</el-button>
        <el-button @click="reset">重置</el-button><br><br>
        <el-button type='primary' class="el-icon-plus" @click="addNewGoods">新建</el-button>
        <el-button @click="batchMatchingBox=true">批量匹配</el-button>
        <el-button @click="goodsImportedBox=true">文件导入</el-button>
        <el-table
            border
            :data="tableData"
            style="width: 100%;margin-top:20px"
            header-cell-style="background:#F5F5F5;font-size:14px;text-align:center"
            cell-style="text-align:center;font-size:14px;">
            <el-table-column
                prop="prop"
                label="商品信息"
                min-width="120">
                <template slot-scope="scope">
                    <div style="display:flex;align-items:center">
                        <div style='width:50px;height:50px;margin-right:10px;line-height:50px'>
                            <img v-if="scope.row.productImageUrl" :src="scope.row.productImageUrl" style="vertical-align:middle" width="100%" alt=""/>
                            <div v-else style="width:100%;height:100%;background:#E8E8E8">
                                <p class="el-icon-picture-outline" style="line-height:50px;text-align:center"></p>
                            </div>
                        </div>
                        <!-- 字 -->
                        <div style="width:150px">
                            <p class="infoText" style="text-align:left">{{scope.row.name}}</p>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="sku"
                label="SKU"
                min-width="100">
            </el-table-column>
            <el-table-column
                prop="productStatusName"
                label="商品状态"
                min-width="100">
                <template slot-scope="scope">
                    <span style="color:#58A55C" v-if="scope.row.productStatusName=='正常销售'">正常销售</span>
                    <span style="color:#FCBF15" v-if="scope.row.productStatusName=='等待开发'">等待开发</span>
                    <span style="color:red" v-if="scope.row.productStatusName=='停止销售'">停止销售</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="matchQuantity"
                label="已匹配数"
                min-width="100">
                <template slot-scope="scope">
                    <el-link @click="openIsMatchedBox(scope.row.id,scope.row.sku)" style="border-bottom:1px dashed gray;display:inline-block">{{scope.row.matchQuantity}}</el-link>
                </template>
            </el-table-column>
            <el-table-column
                prop="prop"
                label="产品尺寸(cm)"
                min-width="100">
                <template slot-scope="scope">
                    <p>{{scope.row.productLength}} * {{scope.row.productWidth}} * {{scope.row.productHeight}}</p>
                </template>
            </el-table-column>
            <el-table-column
                prop="prop"
                label="包装尺寸(cm)"
                min-width="100">
                <template slot-scope="scope">
                    <p>{{scope.row.packLength}} * {{scope.row.packWidth}} * {{scope.row.packHeight}}</p>
                </template>
            </el-table-column>
            <el-table-column
                prop="purchasePrice"
                label="采购成本"
                min-width="100">
                <template slot-scope="scope">
                    <span>{{scope.row.purchaseCostCurrencyName}} {{scope.row.purchasePrice}}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间"
                min-width="100">
            </el-table-column>
            <el-table-column
                prop="prop"
                label="操作"
                min-width="80">
                <template slot-scope="scope">
                    <el-button type="text" @click="getUpdateInit(scope.row.id)">编辑</el-button>
                    <el-button type="text" @click="openMatching(scope.row.id,scope.row.sku)">匹配</el-button>
                    <el-button type="text" @click="del(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;margin:19px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <el-dialog
            :title="title"
            :visible.sync="addGoodsBox"
            width="850px"
            :before-close="addGoodsBoxClose">
            <div style="margin-top:-20px;border-top:1px solid #E5E5E5;padding-top:20px;overflow:hidden;padding-left:10px">
                <el-form :inline="true" label-width="100px">
                    <h3 style="margin-bottom:15px">基础信息</h3>
                    <el-form-item label="图片" style="margin-right:50px">
                        <!-- <el-image 
                        v-if="false"
                        style="width: 148px; height: 148px"
                        :src="dialogImageUrl" 
                        :preview-src-list="picList">
                        </el-image> -->
                        <div v-if="picurl" @click="bigPic=true" style="width:100px;height:100px">
                            <img :src="picurl" alt="" width="100%">
                        </div>
                        <el-upload
                            v-if="!picurl"
                            class="avatar-uploader"
                            ref="minUpload"
                            :action="action3"
                            list-type="picture"
                            :show-file-list=false
                            :file-list='piclist'
                            :on-change="imgPreview"
                            :on-success="uploadSuccess"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                            :before-upload="beforeAvatarUpload">
                            <i class="el-icon-plus"></i><br>
                            <p>上传图片</p>
                            <div>只支持.jpg格式</div>
                        </el-upload>
                        <el-dialog width="50%" append-to-body :visible.sync="bigPic">
                            <img width="100%" :src="picurl" alt="">
                            <el-button type="primary" @click="remove" style="margin:15px;position:relative;left:85%">更换/删除图片</el-button>
                        </el-dialog>
                    </el-form-item>
                    <div style="display:inline-block;width:500px">
                        <el-form-item label="SKU" label-width="40px">
                            <el-input v-model="addInfo.sku" style="width:450px" placeholder="只支持字母和数字组合"></el-input>
                        </el-form-item>
                        <el-form-item label="名称" label-width="40px">
                            <el-input v-model="addInfo.name" style="width:450px"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="备注">
                        <el-input v-model="addInfo.tips" type="textarea" rows="5" cols='85%'  placeholder="请输入备注"></el-input>
                    </el-form-item>
                    <h3 style="margin:10px 0">采购信息</h3>
                    <el-form-item label="产品类型">
                        <el-select v-model="addInfo.productType" filterable placeholder="家具家具" style="width:250px;margin-right:40px">
                            <el-option
                            v-for="item in productTypeList"
                            :key="item.id"
                            :label="item.value" 
                            :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="产品颜色">
                        <el-input placeholder="产品颜色" v-model="addInfo.color" clearable style="width:250px">
                        </el-input>
                    </el-form-item><br>
                    <el-form-item label="采购成本">
                        <el-input type="number" v-model="addInfo.purchasePrice" clearable placeholder="采购成本" style="width:250px;margin-right:40px">
                            <el-select v-model="addInfo.purchasePriceCurrency" slot="append" style="width:80px;color:black" placeholder="币种">
                                <el-option 
                                :label="item.value" 
                                :value="item.id"
                                v-for="(item,index) in currencyTypeList"
                                :key="index"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="采购交期">
                        <el-input type="number" v-model="addInfo.purchaseDeliveryDate" placeholder="采购交期" style="width:250px;">
                            <span slot="append" style="background:white">天</span>
                        </el-input>
                    </el-form-item><br>
                    <el-form-item label="产品规格">
                        <div class="guige" style="margin-right:40px">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')"  v-model="addInfo.productLength" placeholder="长" class="first"></el-input>
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="addInfo.productWidth" placeholder="宽"></el-input>
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="addInfo.productHeight" placeholder="高"></el-input>
                            <el-select v-model="cm" class="last" placeholder="cm">
                                <el-option>cm</el-option>
                            </el-select>
                        </div>
                    </el-form-item>
                    <el-form-item label="包装规格">
                        <div class="guige">
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="addInfo.packLength" placeholder="长" class="first"></el-input>
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="addInfo.packWidth" placeholder="宽"></el-input>
                            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="addInfo.packHeight" placeholder="高"></el-input>
                            <el-select v-model="cm" class="last" placeholder="cm">
                                <el-option>cm</el-option>
                            </el-select>
                        </div>
                    </el-form-item><br>
                    <el-form-item label="单品净重">
                        <el-input v-model="addInfo.unitWeight" type="number" clearable placeholder="单品净重" style="width:250px;margin-right:40px">
                            <span slot="append">g</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="单品毛重">
                        <el-input type="number" v-model="addInfo.unitGrossWeight" placeholder="单品毛重" style="width:250px;">
                            <span slot="append" style="background:white">g</span>
                        </el-input>
                    </el-form-item><br>
                    <el-form-item label="商品状态">
                        <el-select v-model="addInfo.status" filterable placeholder="商品状态" style="width:250px;margin-right:40px">
                            <el-option
                            v-for="item in productStatusList"
                            :key="item.id"
                            :label="item.value" 
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="单箱数量">
                        <el-input type="number" v-model="addInfo.singleBoxNumber" clearable placeholder="单箱数量" style="width:250px">
                        </el-input>
                    </el-form-item><br>
                    <h3 style="margin:10px 0">报关信息</h3>
                    <el-form-item label="中文报关名">
                        <el-input v-model="addInfo.declarationNameCn" placeholder="中文报关名" style="width:250px;margin-right:40px">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="英文报关名" >
                        <el-input v-model="addInfo.declarationNameEn" placeholder="英文报关名" style="width:250px;">
                        </el-input>
                    </el-form-item><br>
                    <el-form-item label="申报价格">
                        <el-input type="number" v-model="addInfo.declarePrice" clearable placeholder="申报价格" style="width:250px;margin-right:40px">
                            <el-select v-model="addInfo.declarePriceCurrency" slot="append" style="width:80px;color:black" placeholder="币种">
                                <el-option 
                                v-for="item in currencyTypeList"
                                :label="item.value" 
                                :value="item.id"
                                :key="item.id"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="海关编码">
                        <el-input v-model="addInfo.customsNo" placeholder="海关编码" style="width:250px;">
                        </el-input>
                    </el-form-item><br>
                </el-form>
            </div>
            <div slot="footer">
                <el-button @click="addGoodsBoxClose">取 消</el-button>
                <el-button :loading='buttonLoading' type="primary" @click="sure">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :title="matchingTitle"
            :visible.sync="matching"
            width="70%"
            :before-close="matchingClose">
            <div>
                <span style="padding-left:100px">商品</span>
                <el-select clearable v-model="getGoodsInfo.status" style="width:180px;margin:0px 10px 0 10px" placeholder="商品状态">
                    <el-option 
                    :label="item.value" 
                    :value="item.id" 
                    v-for="item in product"
                    :key='item.id'></el-option>
                </el-select>
                <el-input clearable v-model="searchByAsin" style="width:300px;margin-right:10px;margin-top:2px" placeholder="请输入ASIN"  class="input-with-select">
                    <el-select clearable @clear="clearType" style="width:100px" v-model="selectAsinType" slot="prepend" placeholder="选择类型">
                        <el-option 
                        :label="item.value"
                        :value="item.id"
                        v-for="item in searchList"
                        :key="item.id"></el-option>
                    </el-select>
                </el-input>
                <el-button type="primary" @click="searchGoods">查询</el-button>
                <div class="Box" v-loading='dialogLoading'>
                    <div>
                        <el-tabs v-model="activeName">
                            <el-tab-pane label="搜索" name="first"></el-tab-pane>
                            <el-tab-pane label="输入列表" name="second">
                            </el-tab-pane>
                        </el-tabs>
                        <el-button type="text" class='all' @click="pushAll">添加全部</el-button>
                        <div v-if="activeName=='first'" v-loading='dialogLoading'>
                            <div style="height:450px;overflow:auto">
                                <div class="item" v-for="(item,index) in goodsList" :key="item.id">
                                    <div class="pic">
                                        <img :src="item.imageUrl" alt="" width="100%">
                                    </div>
                                    <div class="SKU">
                                        <p>
                                            <span class="state"><img :src="item.marketPlaceImageUrl" alt="" width="100%"></span><span>子：{{item.asin}}</span>
                                        </p>
                                        <p>
                                            <span>SKU：{{item.commoditySku}}</span>
                                        </p>
                                    </div>
                                    <div class="realizable">
                                        <p>可售库存：{{item.total}}</p>
                                        <p>可售天数：{{item.canSellDay}}</p>
                                    </div>
                                    <div class="price">
                                        <p>售价：{{currency}}{{item.sellPrice}}</p>
                                    </div>
                                    <div style="font-weight:blod;font-size:23px;color:#409EFF;width:50px">
                                        <span @click="toAdded(item,index)" v-if="item.operation==0&&item.isMatched==0" class="el-icon-circle-plus-outline"></span>
                                        <span style="color:grey;font-size:12px;display:block;width:50px" v-if='item.isMatched==1'>已匹配</span>
                                    </div>

                                </div>
                            </div>
                            <div style="text-align:right;padding-top:20px">
                                <el-pagination
                                background
                                @current-change='changeCurrent'
                                :current-page.sync="getGoodsInfo.current"
                                layout="prev, pager, next"
                                :total="goodsTotal">
                                </el-pagination>
                            </div>
                        </div>
                        <div v-if="activeName=='second'">
                            <el-input 
                            v-model="manualASIN"
                            type="textarea" rows="14" 
                            placeholder="手动输入ASIN,多个时换行输入 如 
    B077ZKF9ZN 
    B07X1QPRYQ 
    B07V2CLJLV" 
                            style="margin-top:15px"></el-input>
                            <el-button style="float:right;margin-top:10px">添加</el-button>
                        </div>
                    </div>
                    <div>
                        <el-tabs v-model="added">
                            <el-tab-pane :label="已添加" name="first"></el-tab-pane>
                        </el-tabs>
                        <el-button type="text" class='all' @click="removeAll">删除全部</el-button>
                        <div style="height:450px;overflow:auto" v-loading='dialogLoading'>
                            <template  v-for="(item,index) in addedList" >
                            <div class="item" :key="item.id">
                                <div class="pic">
                                    <img :src="item.imageUrl" alt="" width="100%">
                                </div>
                                <div class="SKU">
                                    <p>
                                        <span class="state"><img :src="item.marketPlaceImageUrl" alt="" width="100%"></span><span>子：{{item.asin}}</span>
                                    </p>
                                    <p>
                                        <span>SKU：{{item.commoditySku}}</span>
                                    </p>
                                </div>
                                <div class="realizable">
                                    <p>可售库存：{{item.total}}</p>
                                    <p>可售天数：{{item.canSellDay}}</p>
                                </div>
                                <div class="price">
                                    <p>售价：{{currency}}{{item.sellPrice}}</p>
                                </div>
                                <div v-if="item.yet!=1" style="font-weight:blod;font-size:23px;color:#409EFF;width:30px"><span  @click="toGoods(item,index)" class="el-icon-delete"></span></div>
                                <div v-if="item.yet==1" style="color:#999999;font-size:14px;width:50px">已存在</div>
                            </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="matchingClose">取 消</el-button>
                <el-button type="primary" @click="match">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :title="matchTableTitle"
            :visible.sync="matchTableBox"
            width="width"
            :before-close="matchTableBoxClose">
            <div>
                <el-table
                    v-loading="dialogLoading"
                    :data="matchTable"
                    header-cell-style="background:#F5F5F5;font-size:14px;text-align:center"
                    cell-style="text-align:center;font-size:14px;">
                    style="width: 100%">
                    <el-table-column
                        prop="prop"
                        label="商品信息"
                        min-width="120px">
                        <template slot-scope="scope">
                            <div style="display:flex;align-items:center">
                                <div style='width:50px;height:50px;margin-right:10px'>
                                    <img v-if="scope.row.imageUrl" :src="scope.row.imageUrl" alt="" width="100%"/>
                                    <div v-else style="width:100%;height:100%;background:#E8E8E8">
                                        <p class="el-icon-picture-outline" style="line-height:50px;text-align:center"></p>
                                    </div>
                                </div>
                                <!-- 字 -->
                                <div style="width:150px">
                                    <p class="infoText" style="text-align:left;font-size:12px">{{scope.row.productName}}</p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="commoditySku"
                        label="SKU"
                        width="width">
                    </el-table-column>
                    <el-table-column
                        prop="asin"
                        label="ASIN"
                        width="width">
                    </el-table-column>
                    <el-table-column
                        prop="country"
                        label="站点"
                        width="width">
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="UPC"
                        width="width">
                    </el-table-column>
                    <el-table-column
                        prop="upc"
                        label="操作"
                        width="width">
                        <template slot-scope="scope">
                            <el-button type="text"  @click="delMatched(scope.row.id)" style="font-size:20px" class="el-icon-delete"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer">
                <el-button type="primary" @click="matchTableBoxClose">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="批量匹配"
            :visible.sync="batchMatchingBox"
            width="25%"
            :before-close="batchMatchingBoxClose">
            <div style="margin-top:-20px;line-height:20px;font-size:12px">
                <p>1.本地商品与亚马逊商品建立对应关系。</p>
                <p>2.一个本地商品可匹配多个亚马逊商品，而一个亚马逊商品只能对应一个本地商品</p>
                <p>3.最多一次匹配500条记录，多余的将被忽略，如果有超过500条的请多次匹配</p>
                <el-button type="primary" @click="downloadMatched" plain style="width:100%;margin:15px 0">下载空白模板<i class="el-icon-download"></i></el-button>
                <div style="text-align:center">
                    <el-upload
                    style="width:100%"
                    class="upload-demo"
                    :headers="mytoken"
                    :on-success="batchSuccess"
                    :file-list="batchFileList"
                    drag
                    :action="action2"
                    multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="batchMatchingBoxClose">取 消</el-button>
                <el-button type="primary" @click="batchMatchingBoxClose">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="商品导入"
            :visible.sync="goodsImportedBox"
            width="27%"
            :before-close="goodsImportedBoxClose">
            <div v-loading='dialogLoading' style="margin-top:-20px;line-height:20px;font-size:12px">
                <p>1.同的SKU会认为同一个商品，对于多条相同SKU的信息，后上传的信息会覆盖先上的。</p>
                <p>2.对于多条相同的SKU，如果[亚马逊商品SKU]不同，则当条本地商品匹配多条亚马逊商品。</p>
                <p>3.最多一次匹配500条记录，多余的将被忽略，如果有超过500条的请多次匹配</p>
                <el-button type="primary" @click="downfirst" style="margin-top:15px" plain class="muban">
                    <p>模板一、空白模板，只包含表头信息</p>
                    <p>使用场景：添加没有与亚马逊商品型匹配的本地商品</p>
                    <i style="display:block;float:right" class="el-icon-download"></i>
                </el-button><br>
                <el-button type="primary" style="margin-top:-9px" @click="getSecondExcel" plain class="muban">
                    <p>模板二、下载客户名下所有亚马逊上皮的SKU，而本地SKU会默认为SKU</p>
                    <p>使用场景：与亚马逊商品相匹配的本地商品</p>
                    <i style="display:block;float:right" class="el-icon-download"></i>
                </el-button>
                <div style="text-align:center;margin-top:10px">
                    <el-upload
                    style="width:100%"
                    class="upload-demo"
                    :headers="mytoken"
                    :on-success="handleSuccess"
                    :file-list="fileList"
                    drag
                    :action="action"
                    multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="goodsImportedBoxClose">取 消</el-button>
                <el-button type="primary" @click="goodsImportedBoxClose">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { listPage, 
        pageInit, 
        delGoods, 
        addInit, 
        localProduct, 
        updateInit, 
        update, 
        stockListPage, 
        stockPageInit,
        listExistMatchAsin,
        matchingAsin,
        listExistMatchAsinByProductId,
        deleteMatching,
        second} from '../api/CommodityManagement.js';
export default {
    data(){
        var mytoken=window.sessionStorage.getItem('token')
        return {
            cm:'cm',
            batchMatchingBox:false,
            goodsImportedBox:false,
            dialogLoading:false,
            matching:false,
            mytoken:{token:mytoken},
            buttonLoading:false,
            loading:false,
            tableData:[],
            pageInfo:{
                current:'',
                pageSize:'',
                status:'',
                sku:''            
            },
            total:0,
            title:'新增本地商品',
            addGoodsBox:false,
            currencyTypeList:[],
            productStatusList:[],
            productTypeList:[],
            picurl:'',
            bigPic:false,
            id:'',
            addInfo:{
                sku:'',
                name:'',
                tips:'',
                productType:1,
                color:'',
                purchasePrice:'',
                purchaseDeliveryDate:'',
                productLength:'',
                productWidth:'',
                productHeight:'',
                packLength:'',
                packWidth:'',
                packHeight:'',
                unitWeight:'',
                unitGrossWeight:'',
                status:1,
                singleBoxNumber:"",
                declarationNameCn:'',
                declarationNameEn:'',
                declarePrice:'',
                purchasePriceCurrency:'290',
                declarePriceCurrency:'290',
                customsNo:'',
                productFileId:'',
            },
            activeName:'first',
            inputList:'asin',
            manualASIN:'',
            added:'',
            added:'first',
            getGoodsInfo:{
                status:'',
                current:'',
            },
            goodsTotal:'',
            matchingTitle:'',
            searchList:[],
            stockStatusList:[],
            searchByAsin:'',
            selectAsinType:'',
            goodsList:[],
            addedList:[],
            matchTable:[],
            matchTableBox:false,
            fileList:[],
            batchFileList:[],
            messageList:[],
            product:[],
            piclist:[],
        };
    },
    created(){
        this.getAddInit()
        this.getListPage()
        this.getStockPageInit()
    },
    computed:{
        action(){
            return process.env.VUE_APP_API_URL+'/api/localProduct/addProductFile';
        },
        action2(){
            return process.env.VUE_APP_API_URL+'/api/localProduct/batchMatching';
        },
        action3(){
            return process.env.VUE_APP_API_URL+'/api/file/upload/uploadFile';
        }
    },
    methods:{
        batchMatchingBoxClose(){
            this.batchFileList=[]
            this.batchMatchingBox=false
            this.getListPage()
        },
        batchSuccess(res, file, fileList){
            console.log(res, file, fileList);
            this.messageList=res.data
            if(res.code==200){
                this.fileList=fileList
            }else{
                this.batchFileList=fileList.filter(item=>{
                    return item.response.code==200;
                })
            }
            if(this.messageList.length==0&&res.code==200){
                this.$message.success(res.message)
            }else if(res.code==500){
                this.$message.error(res.message)
            }else{
                let str = `<div style="max-height:200px;overflow:auto;padding-left:20px">`
                this.messageList.forEach(item=>{
                    str+= `<p style="line-height:24px">${item}</p>`
                })
                str+=`</div>`
                this.$alert(str, '批量匹配失败项', {
                    dangerouslyUseHTMLString: true
                });
            }
        },
        goodsImportedBoxClose(){
            this.goodsImportedBox=false
            this.fileList=[]
            this.getListPage()
        },
        handleSuccess(res, file, fileList){
            console.log(res, file, fileList);
            if(res.data.code==200){
                this.$message.success(res.data.message)
                this.fileList=fileList
            }else{
                this.$message.error(res.message)
                this.fileList=fileList.filter(item=>{
                    return item.response.code==200;
                })
            }
        },
        // 下载表格
        getSecondExcel(){
            this.dialogLoading=true
            second().then(res=>{
                this.dialogLoading=false
                console.log(res.data.data.data);
                if(res.data.code==200){
                    const link = document.createElement('a')
                    document.body.appendChild(link)
                    link.href =res.data.data.data
                    link.click()
                    document.body.removeChild(link)
                }
            }).catch(()=>{
                this.dialogLoading=false
            })
        },
        downfirst(){
            window.location.href='普通商品模板.xlsx'
        },
        downloadMatched(){
            window.location.href='批量SKU匹配模板.xlsx'
        },
        matchTableBoxClose(){
            this.matchTable=[]
            this.matchTableBox=false
            this.getListPage()
        },
        delMatched(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                deleteMatching(id).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.getListExistMatchAsinByProductId()
                    }else {
                        this.$message.error(res.data.message)
                    }
                }).catch(()=>{
                    this.loading=false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        getListExistMatchAsinByProductId(){
            this.dialogLoading=true
            listExistMatchAsinByProductId({productId:this.id}).then(res=>{
                console.log(res);
                this.dialogLoading=false
                this.matchTable=res.data.data
            })
        },
        openIsMatchedBox(id,sku){
            this.matchTableTitle=`已匹配商品(${sku})`
            this.id=id
            this.matchTableBox=true
            this.getListExistMatchAsinByProductId()
        },
        toGoods(item,index){
            this.goodsList.forEach((i,ind)=>{
                if(i.id==item.id){
                    this.goodsList[ind].operation=0
                }
            })
            this.addedList.splice(index,1)
        },
        pushAll(){
            this.goodsList.forEach((item,index)=>{
                if(item.isMatched!=1){
                    this.goodsList[index].operation=1
                    this.addedList.push(this.goodsList[index])
                }
            })
        },
        changeCurrent(val){
            this.getGoodsInfo.current=val
            this.getStockListPage()
        },
        clearType(){
            this.searchByAsin=''
        },
        searchGoods(){
            this.getGoodsInfo.current=1
            this.getStockListPage()
        },
        matchingClose(){
            this.goodsList=[]
            this.addedList=[]
            this.matching=false
        },
        openMatching(id,sku){
            this.id=id
            this.matching=true
            this.matchingTitle=`匹配商品(${sku})`
            this.dialogLoading=true
            this.getStockListPage()
            this.getListExistMatchAsin()
        },
        removeAll(){
            const arr = []
            this.addedList.forEach((item,index)=>{
                if(item.yet==1){
                    arr.push(item)
                }
            })
            this.addedList=arr
            this.goodsList.forEach((item,index)=>{
                if(item.isMatched==1){
                    this.goodsList[index].operation=1
                }else{
                    this.goodsList[index].operation=0
                }
            })
        },
        match(){
            const ids = []
            this.addedList.forEach(item=>{
                if(item.yet!=1&item.operation==1){
                    ids.push(item.id)
                }
            })
            matchingAsin({asinIds:ids,productId:this.id}).then(res=>{
                console.log(res);
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.matching=false
                    this.getListPage()
                }else {
                    this.$message.error(res.data.message)
                }
            })
        },
        toAdded(item,index){
            console.log(item,index);
            this.goodsList[index].operation=1
            this.addedList.push(this.goodsList[index])
        },
        async getStockPageInit(){
            const {data} = await stockPageInit()
            console.log(data);
            this.searchList=data.data.searchList
            this.product=data.data.productStatusList
        },
        getStockListPage(){
            this.dialogLoading=true
            stockListPage({...this.getGoodsInfo,[this.selectAsinType]:this.searchByAsin?this.searchByAsin:null}).then(res=>{
                this.dialogLoading=false
                console.log(res);
                this.goodsList=res.data.values.map(item=>{
                    item.operation=0
                    return item
                })
                this.goodsTotal=res.data.pageInfo.total
                if(this.addedList.length==0) return
                this.goodsList.forEach((item,index)=>{
                    this.addedList.forEach(i=>{
                        if(item.id==i.id){
                            this.goodsList[index].operation=1
                            this.goodsList[index].yet=1
                        }
                    })
                })
            })
        },
        async getListExistMatchAsin(){
            const {data} = await listExistMatchAsin({productId:this.id})
            this.addedList=data.data.map(item=>{
                item.yet = 1
                item.operation=1
                return item
            })
            this.dialogLoading=false
        },
        getUpdateInit(id){
            this.title='修改本地商品'
            this.id=id
            updateInit(id).then(res=>{
                if(res.data.code!=200){
                    this.$message.error(res.data.message)
                    return
                }
                let {data} = res.data
                console.log(data);
                this.addInfo={
                    sku:data.sku,
                    name:data.name,
                    tips:data.tips,
                    productType:data.productType,
                    color:data.color,
                    purchasePrice:data.purchasePrice,
                    purchaseDeliveryDate:data.purchaseDeliveryDate,
                    productLength:data.productLength,
                    productWidth:data.productWidth,
                    productHeight:data.productHeight,
                    packLength:data.packLength,
                    packWidth:data.packWidth,
                    packHeight:data.packHeight,
                    unitWeight:data.unitWeight,
                    unitGrossWeight:data.unitGrossWeight,
                    status:data.status,
                    singleBoxNumber:data.singleBoxNumber,
                    declarationNameCn:data.declarationNameCn,
                    declarationNameEn:data.declarationNameEn,
                    declarePrice:data.declarePrice,
                    purchasePriceCurrency:String(data.purchasePriceCurrency),
                    declarePriceCurrency:String(data.declarePriceCurrency),
                    customsNo:data.customsNo,
                    productFileId:data.productFileId,
                }
                this.picurl=data.productImageUrl
                this.addGoodsBox=true
            })
        },
        addGoodsBoxClose(){
            this.picurl=''
            this.bigPic=false
            this.addInfo={
                sku:'',
                name:'',
                tips:'',
                productType:'',
                color:'',
                purchasePrice:'',
                purchaseDeliveryDate:'',
                productLength:'',
                productWidth:'',
                productHeight:'',
                packLength:'',
                packWidth:'',
                packHeight:'',
                unitWeight:'',
                unitGrossWeight:'',
                status:'',
                singleBoxNumber:"",
                declarationNameCn:'',
                declarationNameEn:'',
                declarePrice:'',
                purchasePriceCurrency:'290',
                declarePriceCurrency:'290',
                customsNo:'',
                productFileId:'',
            }
            this.id=''
            this.picurl=''
            this.addGoodsBox=false
        },
        remove(){
            this.picurl='',
            this.addInfo.productFileId=''
            this.bigPic=false
        },
        uploadSuccess(res, file, fileList){
            console.log(res, file, fileList);
            if(res.code==200){
                // this.picurl=file.url
                this.addInfo.productFileId=res.data.fileId
            }else{
                this.$message.error(res.message)
            }
        },
        imgPreview(file, fileList) {
            let fileName = file.name;
            let regex = /(.jpg|.jpeg|.gif|.png|.bmp)$/;
            if (regex.test(fileName.toLowerCase())) {
                this.picurl = URL.createObjectURL(file.raw)  // 获取URL
            } else {
                this.$message.error('请选择图片文件');
            }
        },
        sure(){
            // 需要充值表单
            if(this.title=='新增本地商品'){
                this.buttonLoading=true
                localProduct(this.addInfo).then(res=>{
                    this.buttonLoading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.addGoodsBoxClose()
                        this.getListPage()
                    }else {
                        this.$message.error(res.data.message)
                    }
                    console.log(res);
                }).catch(()=>{
                    this.buttonLoading=false
                })
            }
            // 修改
            if(this.title=='修改本地商品'){
                this.buttonLoading=true
                update({...this.addInfo,id:this.id}).then(res=>{
                    this.buttonLoading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.addGoodsBoxClose()
                        this.getListPage()
                    }else {
                        this.$message.erro(res.data.message)
                    }
                    console.log(res);
                }).catch(()=>{
                    this.buttonLoading=false
                })
            }
        },
        addNewGoods(){
            this.title='新增本地商品'
            this.addGoodsBox=true
        },
        getAddInit(){
            addInit().then(res=>{
                console.log(res);
                this.currencyTypeList=res.data.data.currencyTypeList
                this.productStatusList=res.data.data.productStatusList
                this.productTypeList=res.data.data.productTypeList
            })
        },
        reset(){
            this.pageInfo={
                current:'',
                pageSize:'',
                status:'',
                sku:''            
            }
            this.getListPage()
        },
        del(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                delGoods(id).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.getListPage()
                    }else {
                        this.$message.error(res.data.message)
                    }
                }).catch(()=>{
                    this.loading=false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        getPageInit(){
            pageInit().then(res=>{
                this.productStatusList=res.data.data.productStatusList
            })
        },
        getListPage(){
            this.loading=true
            listPage(this.pageInfo).then(res=>{
                this.loading=false
                this.tableData=res.data.values
                this.total=res.data.pageInfo.total
            }).catch(()=>{
                this.loading=false
            })
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getListPage()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getListPage()
        },
        find(){
            this.pageInfo.current=1
            this.getListPage()
        }
    }
}
</script>

<style scoped lang='scss'>
.infoText{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 25px;
}
// ::v-deep .el-upload .el-upload--picture-card{
//     width:50px !important;
//     height:50px !important;
// }
.avatar-uploader{
    font-size: 12px;
    border: 1px dashed #DCDFE6;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    i{
        color: #898989;
        font-size: 20px;
    }
}
::v-deep .el-input-group__append{
    background: white;
    width:10px;
}
::v-deep .el-input-group__prepend{
    background: white;
    width:10px;
}
::v-deep .guige{
    width: 250px;
    display: flex;
    .el-input__inner{
        border-right: 0;
        border-radius: 0 0 0 0;
    }
    .first .el-input__inner{
        border-radius: 4px 0 0 4px;
    }
    .last .el-input__inner{
        border-radius: 0 4px 4px 0;
        border-right:1px solid #DCDFE6;
        width: 70px;
    }
}
.Box{
    display: flex;
    margin: 20px 40px;
    >div{
        overflow: auto;
        flex: 1;
        border: 1px solid #F0F0F0;
        padding: 10px;
        position: relative;
        >.all{
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}
.item{
    height: 85px;
    border-bottom:1px solid #F0F0F0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    .pic{
        width: 70px;
        height: 70px;
        margin-right:5px;
        overflow: hidden;
    }
    .SKU{
        height: 50px;
        width: 150px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .state{
        display: inline-block;
        width: 24px;
        height: 16px;
        vertical-align: middle;
        margin-top: -4px;
    }
    .realizable{
        width: 120px;
        height: 50px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .price{
        width: 120px;
        height: 60px;
        p{
            line-height: 60px;
        }
    }
}
.muban{
    width: 100%;
    display: block;
    text-align: left;
    line-height: 20px;
    padding: 9px;
    position: relative;
    p:nth-of-type(1){
        color: black;
    }
    p:nth-of-type(2){
        color: #999999;
    }
    i{
        display: block;
        font-size: 40px;
        position: absolute;
        top:50%;
        right:0;
        transform: translateY(-50%);
    }
}
::v-deep .el-upload{
    width: 100%;
    .el-upload-dragger{
        width: 100%;
    }
}
::v-deep .el-upload-list__item-name{
        text-align: left;
    }
</style>